<template>
  <i class="icon" v-html="iconHtml" />
</template>

<script setup>
  import {onMounted, ref} from 'vue';
  import {defineProps} from 'vue';
  import {iconService} from '@/flows/app/icons/services/iconService.js';

  const props = defineProps({
    name: {
      type: String,
      required: true
    },
  });

  const iconHtml = ref('');

  onMounted(async () => {
    iconHtml.value = await iconService.fetchIcon(props.name);
  });
</script>
