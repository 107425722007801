<template>
  <app-modal-mini
    :submit-txt="__t('checkout.validation.notification.submit')"
    :hide-cancel="minOrder"
    @submitted="modalController.dismiss"
  >
    <template #header>
      <h3>
        <Icon name="info" />
        {{ __t('checkout.validation.order.title') }}
      </h3>
    </template>

    <template #main>
      <div class="messages">
        <ul v-if="messages.length > 0">
          <li v-for="(message, i) in messages" :key="`message_${i}`">
            {{ message }}
          </li>
        </ul>
      </div>

      <div v-if="currentEstablishment" class="link">
        <span @click="goToEstablishment">
          <Icon name="bag" />
          {{ __t('app.continue-shopping') }}
        </span>
      </div>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import {onBeforeMount, ref, defineProps} from 'vue';
  import { Icon } from "@/ui";
  import { __t } from "@/globals";
  import { modalController } from "@ionic/vue";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import { useRouter } from 'vue-router';
  import {cartService} from "@/flows/order/cart/services/cartService.js";

  defineProps({
    messages: { type: Array, default: () => [] },
    minOrder: { type: Boolean, default: false }
  });

  const router = useRouter();

  const currentEstablishment = ref(null);

  const goToEstablishment = async () => {
    await router.push({name: 'establishment', params: {slug: currentEstablishment.value.slug}});
    await modalController.dismiss();

  };

  onBeforeMount(async () => {
    currentEstablishment.value = await cartService.getEstablishment();
  });
</script>

<style lang="scss">
.ion-modal-notification {
  --height: 42rem;
  .messages {
    background-color: var(--r2e-primary-100);
    padding: var(--padding-m) var(--padding-m);
    border: 1px solid var(--r2e-error);

    ul li {
      list-style: disc;
      margin-left: var(--margin-s);
      font-size: 1.3rem;
      color: var(--r2e-error);
      font-weight: 500;
      margin-bottom: var(--margin-s);
    }
  }

  .link {
    display: flex;
    justify-content: center;
    margin-top: var(--margin-m);
    span {
      display: flex;
      border: 2px solid var(--r2e-primary);
      width: max-content;
      border-radius: 99rem;
      font-size: 1.2rem;
      padding: 0.4rem 1.2rem;
      line-height: 1;
      font-weight: 600;
      text-transform: lowercase;
      color: var(--r2e-primary);

      svg {
        margin-right: var(--margin-s);
        width: 1.2rem;
        height: 1.2rem;
        color: var(--r2e-primary);
      }
    }
  }
}

[data-theme='dark'] {
  .messages {
    background-color: var(--r2e-primary-700);
  }
}
</style>
