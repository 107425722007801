<template>
  <app-modal-mini
    :submit-txt="__t('app.add-comment')"
    @submitted="handleSubmit"
  >
    <template #header>
      <h3>{{ __t('app.modal.comment.title') }}</h3>
    </template>

    <template #main>
      <Textarea
        id="comment"
        :value="comment"
        :placeholder="__t('app.modal.comment.title')"
        @set="setVal"
      />
    </template>
  </app-modal-mini>
</template>

<script setup>
  import { ref, defineProps } from 'vue';
  import {$showToast, __t} from "@/globals";
  import { Textarea } from "@/ui";
  import { modalController } from "@ionic/vue";
  import {cartService} from "@/flows/order/cart/services/cartService.js";
  import AppModalMini from "@/modals/AppModalMini.vue";

  const props = defineProps({
    type: { type: String, required: true },
    item: { type: Object, required: false, default: () => null },
  });

  const comment = ref(props.item?.comment || '');

  function setVal(val) {
    comment.value = val;
  }

  async function handleSubmit() {
    await cartService.addCartLineComment(props.item.uuid, comment.value);

    $showToast(__t('success.comment.added'), 'success');

    await modalController.dismiss();
  }
</script>

<style lang="scss">

</style>
