import Tabs from "@/views/Tabs.vue";

export default [
  {
    path: '/',
    name: 'welcome',
    redirect: '/tabs/welcome',
  },

  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/flows/user/onboarding/views/Onboarding.vue'),
    meta: {
      bgColor: false,
      hideTabs: true
    }
  },

  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/order',
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },
      {
        name: 'catalogue',
        path: 'order',
        component: () => import('@/views/Catalogue.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/Cart.vue'),
        meta: {
          bgColor: false,
          hideTabs: false,
          deliveryCheck: true
        }
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/flows/user/user/views/Account.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },
      {
        name: 'native.welcome',
        path: 'welcome',
        component: () => import('@/views/Welcome.vue'),
        meta: {
          bgColor: true,
          hideTabs: true
        }
      },
      {
        name: 'native.login',
        path: 'login',
        component: () => import('@/flows/user/authentication/views/NativeLogin.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },
      {
        name: 'native.register',
        path: 'register',
        component: () => import('@/flows/user/authentication/views/Register.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'login',
        path: '/login',
        component: () => import('@/flows/user/authentication/views/NativeLogin.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'register',
        path: '/register',
        component: () => import('@/flows/user/authentication/views/Register.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'register.code',
        path: '/register/verify',
        component: () => import('@/flows/user/authentication/views/RegisterCode.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'register.code.without-login',
        path: '/register/verify/without-login',
        component: () => import('@/flows/user/authentication/views/RegisterCodeWithoutLogin.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'register.code.social',
        path: '/register/social/verify',
        component: () => import('@/flows/user/authentication/views/RegisterSocialCode.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'forgot-password',
        path: '/forgot-password',
        component: () => import('@/flows/user/authentication/views/ForgotPassword.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'request-password-reset',
        path: '/request-password-reset',
        component: () => import('@/flows/user/authentication/views/RequestResetPassword.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'password-reset',
        path: '/password-reset',
        component: () => import('@/flows/user/authentication/views/ResetPassword.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'establishment',
        path: '/order/:slug',
        component: () => import('@/views/Establishment.vue'),
        meta: {
          bgColor: false,
          hideTabs: false,
          deliveryCheck: true
        }
      },

      {
        name: 'checkout',
        path: '/checkout',
        component: () => import('@/views/Checkout.vue'),
        meta: {
          bgColor: false,
          hideTabs: true,
          deliveryCheck: true
        }
      },

      {
        name: 'follow-order',
        path: '/follow-order/:nr',
        component: () => import('@/flows/order/follow/components/views/FollowOrderView.vue'),
        meta: {
          bgColor: false,
          hideTabs: true,
        }
      },

      // {
      //   name: 'order-follow-by-nr',
      //   path: '/follow-order/:nr',
      //   component: () => import('@/flows/order/follow/components/views/FollowOrderView.vue'),
      //   meta: {
      //     bgColor: false,
      //     hideTabs: true
      //   }
      // },

      {
        name: 'order-history',
        path: '/order-history',
        component: () => import('@/views/OrderHistory.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },

      {
        name: 'order-history-detail',
        path: '/order-history/:nr',
        component: () => import('@/views/OrderHistoryDetail.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },

      {
        name: 'account.update',
        path: '/profile/edit',
        component: () => import('@/flows/user/user/views/AccountUpdate.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },

      {
        name: 'contact',
        path: '/contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
          bgColor: false,
          hideTabs: false
        }
      },

      {
        name: 'settings',
        path: '/settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'no-connection',
        path: '/no-connection',
        component: () => import('@/views/errors/ErrorNoConnection.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'not-found',
        path: '/404',
        component: () => import('@/views/errors/Error404.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        name: 'maintenance',
        path: '/maintenance',
        component: () => import('@/views/errors/ErrorMaintenance.vue'),
        meta: {
          bgColor: false,
          hideTabs: true
        }
      },

      {
        path: '/:pathMatch(.*)*',
        redirect: 'tabs/404',
      },
    ]
  },
]
