<template>
  <app-modal-mini
    :submit-txt="__t('app.auth.modal.auth-token-expired.submit')"
    @submitted="submit"
  >
    <template #header>
      <h3>{{ __t('app.auth.modal.auth-token-expired.title') }}</h3>
    </template>

    <template #main>
      <p>{{ __t('app.auth.modal.auth-token-expired.body') }}</p>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import { defineProps } from 'vue';
  import {__t} from "@/globals";
  import { modalController } from "@ionic/vue";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {useRouter} from "vue-router";

  const router = useRouter();

  defineProps({
    item: { type: Object, required: false, default: () => null },
  });

  const submit = async () => {
    await router.push({name: 'login'});

    await modalController.dismiss();
  }
</script>

<style lang="scss">
.ion-modal-authentication-token-expired {
  --height: 30rem;

  .modal-main {
    margin: auto auto;
    max-width: 38rem;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    span {
      font-size: 1.2rem;
      text-align: center;
      margin-top: var(--margin-m);
      font-weight: 500;
      color: var(--color-grey-500);
      cursor: pointer;

      &:hover {
        color: var(--color-black);
      }
    }
  }


}
</style>
