import routes from "@/router/routes.js";
import {createRouter, createWebHistory} from "@ionic/vue-router";
import router from "@/router/index.js";
import PlatformService from "@/core/PlatformService.js";
import {preferenceService} from "@/flows/app/preferences/services/preferenceService.js";

export class LocaleService {
  static generateRoutes(locale) {
    return routes.map(route => {
      return {
        path: route[`path_${locale}`],
        name: route.name,
        component: route.component,
      };
    });
  }
  static async changeLocale(key, currentRoute) {
    if (['nl', 'en', 'fr', 'tr'].includes(key)) {
      await preferenceService.setLanguage(key);

      preferenceService.setLocale(key);

      const newRouter = createRouter({
        scrollBehavior() {
          return { top: 0 };
        },
        history: createWebHistory('/'),
        routes: PlatformService.isNative() ? routes : this.generateRoutes(key)
      });

      // replace router
      router.matcher = newRouter.matcher;

      let newRoute = newRouter.options.routes.find(route =>
        route.name === currentRoute.name
      );

      if(!newRoute) {
        return newRouter.push({ name: 'catalogue' });
      }

      return newRouter.push({ name: newRoute.name, params: currentRoute.params });

    }
  }
}
