import {$openModal} from "@/globals.js";
import {userService} from "@/flows/user/user/services/UserService.js";
import {userAuthenticationService} from "@/flows/user/authentication/services/UserAuthenticationService.js";


export async function authTokenExpiredMiddleware(to) {
  const tokenData = await userService.getToken();
  const authenticated = await userService.isAuthenticated();

  if(authenticated) {
    if(!tokenData.issued_at) {
      await userAuthenticationService.logout();
      await $openModal('authentication-token-expired');
      return;
    }

    const now = Math.floor(Date.now() / 1000);
    const expiresAt = tokenData.issued_at + tokenData.expires_in;

   if(now >= expiresAt) {
     await userAuthenticationService.logout();
     await $openModal('authentication-token-expired');
   }
  }
}

