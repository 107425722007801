<template>
  <ion-header :class="{'native': native}" />
  <ion-content class="ion-padding content-radius">
    <div v-if="!loading" class="modal-article" :class="modalClass">
      <ArticleModalHeader
        :article="article"
        @close="close"
      />
      <ArticleModalMain
        :article="article"
        :delivery="deliveryMode === 'delivery'"
        :cart-item="cartItem"
        @update-supplement="updateSupplement"
        @add-item="addItem"
        @min-item="minItem"
        @submit-article="submitArticle"
      />
    </div>
    <ArticleSkeleton
      v-else
      :class="{'native':native}"
      @close="close"
    />
  </ion-content>
  <ion-footer style="box-shadow: none; padding-bottom: var(--padding-s)">
    <ArticleModalFooter
      v-if="!loading"
      :loading="loadingEstablishment"
      :cart-item="cartItem"
      @add-item="addItem"
      @min-item="minItem"
      @submit-article="submitArticle"
    />
  </ion-footer>
</template>

<script setup>
  import { computed, ref, defineProps, onMounted } from 'vue';
  import {IonContent, IonHeader, IonFooter, modalController} from '@ionic/vue';
  import { v4 as uuid } from 'uuid';
  import { $showToast, __t } from '@/globals';
  import ArticleModalMain from "@/modals/parts/ArticleModalMain.vue";
  import ArticleModalHeader from "@/modals/parts/ArticleModalHeader.vue";
  import ArticleSkeleton from "@/modals/parts/ArticleSkeleton.vue";
  import ArticleModalFooter from "@/modals/parts/ArticleModalFooter.vue";
  import {cartService} from "@/flows/order/cart/services/cartService.js";
  import {userService} from "@/services/app/userService.js";
  import PlatformService from "@/core/PlatformService.js";
  import {articleService} from "@/flows/order/article/services/ArticleService.js";
  import {addressService} from "@/flows/app/address/services/AddressService.js";
  import {catalogueService} from "@/flows/app/catalogue/services/CatalogueService.js";

  const props = defineProps({
    articleId: { type: Number, required: true },
    establishmentSlug: { type: String, required: true },
  });

  const deliveryMode = ref('delivery');
  const address = ref(null);
  const loading = ref(true);
  const loadingEstablishment = ref(true);
  const establishment = ref(null);
  const article = ref(null);
  const cartItem = ref(null);
  const native = PlatformService.isNative();

  const close = () => modalController.dismiss();
  const addItem = () => cartItem.value = articleService.addQuantityAndGetItem(cartItem.value);
  const minItem = () => {
    if(cartItem.value.quantity !== 1){
      cartItem.value = articleService.minQuantityAndGetItem(cartItem.value);
    }
  }
  const updatePrice = () => cartItem.value = articleService.updateSupplementsAndGetItem(cartItem.value);

  const submitArticle = async () => {
    await cartService.addCartLine(cartItem.value, establishment.value);

    $showToast(__t('success.item.added'), 'success');

    await close();
  };

  const updateSupplement = (group, supplement) => {
    if (group.type === 'single') {
      group.supplements.forEach(supp => supp.selected = false);
      if (supplement) group.supplements.find(supp => supp.id === supplement.id).selected = true;
    } else if (group.type === 'multiple') {
      group.supplements.find(supp => supp.id === supplement.id).selected =
        !group.supplements.find(supp => supp.id === supplement.id).selected;
    }
    cartItem.value.supplement_groups.find(gr => gr.id === group.id).supplements = group.supplements;
    updatePrice();
  };

  const setCartItem = () => {
    const article_price = deliveryMode.value === 'delivery'
      ? article.value.price_delivery
      : article.value.price_takeout;

    cartItem.value = {
      uuid: uuid(),
      article: article.value,
      supplement_groups: article.value.supplement_groups,
      quantity: 1,
      price_unit: article_price,
      price_quantity: article_price,
      price_supplements: 0,
      price_total: 0,
      establishment_slug: props.establishmentSlug,
      comment: null
    };

    cartItem.value.price_total = (article_price + cartItem.value.price_supplements) * cartItem.value.quantity;
  };

  const fetchArticle = async () => {
    loading.value = true;

    try {
      const establishmentSlug = props.establishmentSlug;
      const articleId = props.articleId;

      article.value = await articleService.getArticle(establishmentSlug, articleId);

      setCartItem();

      loading.value = false;
    } catch {
      $showToast(__t('error.article.fetch'), 'error');
    }
  };

  const fetchEstablishment = async () => {
    try {
      loadingEstablishment.value = true;

      const establishmentSlug = props.establishmentSlug;

      const coordinates = await addressService.getAddressCoordinates();

      establishment.value = await catalogueService.getEstablishment(
        establishmentSlug,
        coordinates.longitude,
        coordinates.latitude
      );

      loadingEstablishment.value = false;
    } catch {
      $showToast(__t('error.establishment.fetch'), 'error');
    }
  };

  onMounted(async () => {
    address.value = await userService.getUserAddress();
    deliveryMode.value = await cartService.getDeliveryMode();

    await fetchArticle();

    await fetchEstablishment();
  });

  const modalClass = computed(() => ({
    'with-image': article.value?.image_path,
    'with-supplements': article.value?.supplement_groups.length > 0
  }));
</script>

<style lang="scss">
ion-modal::part(content) {
  touch-action: none;
}

ion-modal {
  --border-radius: 1rem;

  @media(min-width: 768px){
    --border-radius: 1rem;
    --height: 65rem;
  }
}

.ion-modal-article {
  ion-header {
    padding: 0 !important;
  }
}

[data-theme='dark'] {
  .ion-modal-article {
    ion-header {
      padding: 0 !important;
    }

    ion-modal {
      --background: var(--r2e-secondary);
    }

    ion-footer {
      background-color: var(--r2e-secondary);
    }

    div.modal-main {
      h2 {
        color: var(--r2e-white);
        svg {
          fill: var(--r2e-white);
        }
      }
    }

    div.modal-main {
      .toggle-group {
        color: var(--r2e-white);
        span {
          color: var(--r2e-white);
        }

        div.article-description, div.allergies i {
          color: var(--r2e-secondary-200);
        }
      }

    }

    div.modal-footer {

      button.r2e-button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }

      span {
        color: var(--r2e-secondary-200);
      }
    }
  }

}
</style>
