<template>
  <div class="nav-header" :class="{'native': native}">
    <container>
      <div class="profile-section">
        <div class="avatar">
          <ProfileAvatar />
        </div>
        <div class="info">
          <template v-if="!authenticated">
            <h2>{{ __t('nav.welcome') }}</h2>
            <a @click.prevent="navigate('login')">{{ __t('nav.login-link') }}</a>
          </template>
          <template v-else-if="user">
            <h2>{{ __t('nav.welcome') }} {{ user.first_name + ' ' + user.last_name }}</h2>
            <a @click.prevent="navigate('account')">{{ __t('nav.profile') }}</a>
          </template>
        </div>
      </div>



      <button class="btn-exit" @click="close">
        <Icon name="exit" />
      </button>
    </container>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits, ref, onBeforeMount, computed, watch} from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import ProfileAvatar from "@/modules/profile/parts/ProfileAvatar.vue";
  import Container from "@/layout/layout/Container.vue";
  import {__t} from "@/globals.js";
  import PlatformService from "@/core/PlatformService.js";
  import {userService} from "@/services/app/userService.js";
  import store from "@/store/store.js";
  import LangSwitcher from "@/modules/global/parts/LangSwitcher.vue";

  defineProps({
    fullName: {
      type: String,
      default: '',
    },
  });

  const authenticated = ref(false);
  const user = ref(null);
  const native = PlatformService.isNative();
  const userUpdated = computed(() => store.getters['isUserUpdated']);

  const emit = defineEmits(['close', 'navigate']);

  function close() {
    emit('close');
  }

  function navigate(route) {
    emit('navigate', route);
  }

  const initData = async () => {
    authenticated.value = await userService.isAuthenticated();
    user.value = await userService.getUser();
  }

  onBeforeMount(async () => {
    await initData();
  });

  watch(userUpdated, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await initData();
    }
  });
</script>

<style lang="scss">
.nav-header {
  padding-bottom: var(--padding-m);

  .container {
    display: flex;
    align-items: center;
  }




  div.profile-section {
    margin-top: var(--margin-l);
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .nav-lang-switcher {
      position: absolute;
      top: 0;
      right: 0;
    }



    div.avatar {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i svg {
        fill: #fff;
        width: 1.8rem;
        height: 1.8rem;
      }
    }



    div.info {
      margin-left: var(--margin-s);
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1;
        text-transform: capitalize;
        margin-bottom: 4px
      }

      a {
        font-size: 1rem;
        line-height: 1;
        font-weight: 600;
        text-transform: lowercase;
        color: var(--r2e-primary);
      }
    }
  }

  button.btn-exit {
    background-color: transparent;
    margin-bottom: auto;
    margin-left: auto;
    width: 1.4rem;
    height: 1.4rem;
    transition: var(--effect);

    svg {
      width: 1.4rem;
      height: 1.4rem;
      fill: var(--color-black);
    }

    &:hover {
      transform: scale(1.08);
    }
  }

  @media(min-height: 600px) {
    div.profile-section {

      div.avatar {
        width: 5.6rem;
        height: 5.6rem;

        i svg {
          fill: #fff;
          width: 2.8rem;
          height: 2.8rem;
        }
      }

      div.info {
        margin-left: var(--margin-m);
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 1;
          text-transform: capitalize;
          margin-bottom: var(--margin-xs);
        }

        a {
          padding: 0.2rem 1rem;
          border: 2px solid var(--r2e-primary);
          width: max-content;
          border-radius: 99rem;
          font-size: 1rem;
          line-height: 1;
          font-weight: 600;
          text-transform: lowercase;
          color: var(--r2e-primary);
        }
      }
    }
  }
}

[data-theme='dark'] {
  .nav-header {
    background-color: var(--r2e-secondary-600);

    .btn-exit {
      svg {
        fill: var(--r2e-secondary-300);
      }
    }

    .profile-section {
      div.avatar {

      }

      div.info {
        h2 {
          color: var(--r2e-white);
        }
      }
    }
  }
}
</style>
