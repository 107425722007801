<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup>
  import { onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
  import {IonApp, IonRouterOutlet} from "@ionic/vue";
  import {$openModal} from "@/globals.js";
  import {apiUrl} from "@/configuration/api.js";
  import axios from "axios";
  import {useRouter} from "vue-router";
  import {Network} from "@capacitor/network";
  import PlatformService from "@/core/PlatformService.js";
  import {preferenceService} from "@/flows/app/preferences/services/preferenceService.js";
  import {authService} from "@/services/auth/authService.js";
  import { SplashScreen } from '@capacitor/splash-screen';
  import {appConfigurationService} from "@/modules/app-configuration/services/AppConfigurationService.js";

  const router = useRouter();
  const connected = ref(true);

  const onboardingCompleted = ref(null);

  function checkTimeAndApplyTheme() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 22 || hour < 6) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  async function checkAppVersion() {
    if(PlatformService.isNative()){
      return;
    }

    const currentVersion = await preferenceService.getAppVersion();

    await axios.get(apiUrl('customer/version')).then(({data}) => {
      const newVersion = data.data.version;

      if(currentVersion === newVersion){
        console.log('app is up to date');
      } else {
        console.log('newVersion:', newVersion);


        $openModal('update-version', {version: newVersion });
      }
    });
  }

  async function checkInternetConnection() {
    const status = await Network.getStatus();
    connected.value = status.connected;

    if (!connected.value) {
      await router.push({ name: 'no-connection' });
    }

    Network.addListener('networkStatusChange', (status) => {
      connected.value = status.connected;

      if (!connected.value) {
        router.push({ name: 'no-connection' });
      }
    });
  }

  const checkIfOnboardingCompleted = async () => {
    if (PlatformService.isNative()) {
      onboardingCompleted.value = preferenceService.getOnboardingCompleted();
      if (!onboardingCompleted.value) {
        await authService.logout();

        await router.push({name: 'welcome'});
      }

    }
  }

  onBeforeMount(async () => {
    checkTimeAndApplyTheme();

    await checkIfOnboardingCompleted();
  });

  watch(connected, (newConnected) => {
    console.log('connected:', newConnected);
  });

  onMounted(async () => {
    await appConfigurationService.initializeEvent();
    await checkIfOnboardingCompleted();
    await checkAppVersion();
    await checkInternetConnection();
    await SplashScreen.hide();
  });

  onUnmounted(() => {
    appConfigurationService.stopEvent();
  });
</script>

<style lang="scss">
  :root {
    --app-background: var(--r2e-background);
    --ion-background-color: var(--r2e-background);
  }

  [data-theme="dark"] {
    :root {
      --app-background: var(--r2e-secondary-600);
      --ion-background-color: var(--r2e-secondary-600);
    }

    * {
      --app-background: var(--r2e-secondary-600);
      --ion-background-color: var(--r2e-secondary-600);
    }
  }

  .alert-wrapper.sc-ion-alert-md {
    background-color: var(--r2e-white);
    font-family: 'Poppins', sans-serif;
  }

  .ios .test-alert button.alert-button {
    font-weight: 600;
    font-size: 1.2rem;
  }
</style>
