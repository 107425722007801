import * as modals from "@/configuration/modals";
import {createApp, h} from "vue";
import {Toast} from "@/ui";
import {preferenceService} from "@/flows/app/preferences/services/preferenceService.js";

function __t(key, params) {
  if(!window.translations){
    return key;
  }

  let allTranslations = window.translations;

  // Find the translation for the given key
  const value = allTranslations.find(t => t.key === key);

  //current locale
  const locale = preferenceService.getLocale() ?? 'nl';

  if(!value) {
    return key;
  }

  let translation = value['value_' + locale];

  if(params && translation) {
    for(const [key, value] of Object.entries(params)) {
      translation = translation.replace(`{${key}}`, value);
    }
  }

  return translation;
}

function $openModal(name, params) {
  switch (name) {
    case 'navigation' :
      return modals.openNavigationModal();
    case 'search-location' :
      return modals.openSearchLocationModal();
    case 'filter' :
      return modals.openCatalogueFilterModal();
    case 'article' :
      return modals.openArticleModal(params);
    case 'delivery-time' :
      return modals.openDeliveryTimeModal();
    case 'loading' :
      return modals.openLoadingModal();
    case 'takeaway-time' :
      return modals.openTakeAwayTimeModal();
    case 'payment-method' :
      return modals.openPaymentMethodModal();
    case 'ready2help' :
      return modals.openReady2HelpModal();
    case 'discount-code' :
      return modals.openDiscountCodeModal();
    case 'comment-modal' :
      return modals.openCommentModal(params);
    case 'terms' :
      return modals.openTermsModal(params);
    case 'notification' :
      return modals.openNotificationModal(params);
    case 'establishment-info' :
      return modals.openEstablishmentInfo(params);
    case 'contact-fields' :
      return modals.openContactFieldsModal();
    case 'token':
      return modals.openTokenModal();
    case 'registering-code':
      return modals.openRegisteringCodeModal();
    case 'verify-email':
        return modals.openVerifyEmailModal();
    case 'global-discount':
      return modals.openGlobalDiscountCodeModal(params);
    case 'update-version':
      return modals.openUpdateVersionModal(params);
    case 'delete-account':
      return modals.openDeleteAccountModal();
    case 'authentication-token-expired':
      return modals.openAuthenticationTokenExpiredModal();
  }
}

function $showToast(text, status, timeout){
  const toast = createApp({
    setup () {
      return () => h(Toast, {
        status : status,
        title : text,
        timeout: timeout,
        onClose: () => {
          toast.unmount();
        }
      })
    }
  });

  const popup = document.createElement('div');

  document.body.appendChild(popup);

  toast.mount(popup);

  popup.remove();
}

function $changeThemeColor(color) {

  /*if(!color) {
    color= '#F8FDFE';
  }

  // Change the theme color
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  metaThemeColor.setAttribute("content", color);

  // You might also want to change the splash screen background for future launches
  // This requires updating the manifest, which is not standard and may not be supported across all browsers
  const manifestLink = document.querySelector('link[rel="manifest"]');
  fetch(manifestLink.href)
    .then((response) => response.json())
    .then((manifest) => {
      manifest.background_color = color;
      manifest.theme_color = color;
      const blob = new Blob([JSON.stringify(manifest)], {type: 'application/json'});
      const newManifestURL = URL.createObjectURL(blob);
      manifestLink.href = newManifestURL;
    });*/
}

export {
  __t,
  $openModal,
  $showToast,
  $changeThemeColor,
}
