import {cartService} from "@/flows/order/cart/services/cartService.js";
import {ArticleRepository} from "@/flows/order/article/repositories/ArticleRepository.js";

class ArticleService {
  constructor()  {
    this.articleRepository = new ArticleRepository();
    this.cartService = cartService;
  }

  async getArticle(establishment_slug, id) {
    try {
      return await this.articleRepository.fetchArticle(establishment_slug, id);
    } catch (error) {
      console.error('Failed to fetch article:', error);
      throw new Error(error);
    }
  }

  addQuantityAndGetItem(item){
    const cartItem = item;
    cartItem.quantity++;
    cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

    return cartItem;
  }

  minQuantityAndGetItem(item){
    if(item.quantity > 1){
      const cartItem = item;
      cartItem.quantity--;
      cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

      return cartItem;
    }
  }

  updateSupplementsAndGetItem(item){
    let total = 0;

    item.supplement_groups.forEach(group => {
      group.supplements.forEach((supplement) => {
        if(supplement.selected === true){

          const supplement_price = supplement[(cartService.getDeliveryMode() === 'delivery')
            ? 'price_delivery'
            : 'price_takeout']

          total += supplement_price;
        }
      })
    });

    const cartItem = item;

    cartItem.price_supplements = total;

    cartItem.price_total = (item.price_unit + total) * (item.quantity);

    return cartItem;
  }

}

export const articleService = new ArticleService();
