import axios from "axios";
import {userService} from "@/services/app/userService.js";
import PlatformService from "@/core/PlatformService.js";
import SocialAuthNativeService from "@/services/auth/SocialAuthNativeService.js";
import SocialAuthWebService from "@/services/auth/SocialAuthWebService.js";
import router from "@/router/index.js";
import {$showToast, __t} from "@/globals.js";
import store from "@/store/store.js";
import {apiUrl} from "@/configuration/api.js";
import {SavePassword} from 'capacitor-ios-autofill-save-password';

class AuthService {
  async login(payload, provider) {
    try {
      switch (provider) {
        case 'email':
          return await this.loginWithEmail(payload);
        case 'facebook':
          return await this.loginWithFacebook();
        case 'google':
          return await this.loginWithGoogle();
        case 'apple':
          return await this.loginWithApple();
        default:
          return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async loginWithEmail(payload) {
    try {
      const tokenData = await this.getTokenResponse(payload);

      if (!tokenData) {
        throw new Error('Failed to get token data');
      }

      throw new Error('Failed to get token data');

      if(tokenData.issued_at){
        tokenData.issued_at = Math.floor(Date.now() / 1000);
      }

      await userService.setAuthTokenData(tokenData);

      const user = await this.getUserResponse(tokenData.access_token);

      if(user.provider) {
        await userService.setProvider(user.provider);
      }

      await userService.setUser(user);

      await userService.removeRegisterData();

      if (PlatformService.isIOS()) {
        await SavePassword.promptDialog({
          username: payload.email,
          password: payload.password
        });
      }

    } catch (error) {
      throw new Error(error);
    }
  }

  async getTokenResponse(payload) {
    try {
      const tokenResponse = await axios.post(`${process.env.API_URL}/oauth/token`, {
        grant_type: 'password',
        client_id: 2,
        client_secret: process.env.API_KEY_SECRET,
        username: payload.email,
        password: payload.password
      });

      return tokenResponse.data;
    } catch (error) {
      console.log('error', error);
    }
  }

  async getUserResponse(token) {
    try {
      const userResponse = await axios.get(`${process.env.API_URL}/v1/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return userResponse.data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async loginWithFacebook() {
    try {
      let socialAuthService;

      if (PlatformService.isNative()) {
        socialAuthService = new SocialAuthNativeService();
      } else {
        socialAuthService = new SocialAuthWebService();
      }

      const accessToken = await socialAuthService.loginWithFacebook();

      await axios.post(`${process.env.API_URL}/v1/facebook`, {token: accessToken}).then(async ({data}) => {
        const {user, access_token, expires_in} = data.data;

        await userService.setAuthTokenData({access_token, expires_in});

        await userService.setUser(user);

        await userService.setProvider('facebook');

        await store.dispatch('updatedUser').then(() => {
          $showToast(__t('success.login'), 'success');
          router.push({name: 'catalogue'});
        });
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  async loginWithGoogle() {
    try {
      let socialAuthService;

      if (PlatformService.isNative()) {
        socialAuthService = new SocialAuthNativeService();
      } else {
        socialAuthService = new SocialAuthWebService();
      }

      const accessToken = await socialAuthService.loginWithGoogle();

      await axios.post(`${process.env.API_URL}/v1/google`, {token: accessToken})
        .then(async ({data}) => {

          const {user, access_token, expires_in} = data.data;

          await userService.setAuthTokenData({access_token, expires_in});

          await userService.setUser(user);

          await userService.setProvider('google');

          await store.dispatch('updatedUser').then(() => {
            $showToast(__t('success.login'), 'success');
            router.push({name: 'catalogue'});
          });
        });

    } catch (error) {
      throw new Error(error);
    }
  }

  async loginWithApple() {
    try {
      let socialAuthService;

      if (PlatformService.isNative()) {
        socialAuthService = new SocialAuthNativeService();
      } else {
        throw new Error('Apple sign in not supported in web');
      }

      const {token, userResponse} = await socialAuthService.loginWithApple();

      if (!token) {
        throw new Error('Apple sign in failed');
      }

      await axios.post(`${process.env.API_URL}/v1/apple`, {token: token, user_data: userResponse})
        .then(async ({data}) => {

          const {user, access_token, expires_in} = data.data;

          await userService.setAuthTokenData({access_token, expires_in});

          await userService.setUser(user);

          await userService.setProvider('apple');

          await store.dispatch('updatedUser').then(() => {
            $showToast(__t('success.login'), 'success');
            router.push({name: 'catalogue'});
          });
        });

    } catch (error) {
      throw new Error(error);
    }
  }

  async getAppleTokenResponse(appleCredential) {
    try {
      const tokenResponse = await axios.post(`${process.env.API_URL}/oauth/token`, {
        grant_type: 'apple',
        client_id: 2,
        client_secret: process.env.API_KEY_SECRET,
        apple_id_token: appleCredential.identityToken,
        apple_user: appleCredential.user
      });

      return tokenResponse.data;
    } catch (error) {
      console.log('error', error);
      throw new Error(error);
    }
  }

  async register(payload) {
    try {
      const response = await axios.post(apiUrl('register'), payload);
      const user = response.data.data.user;

      await userService.setRegisterData({
        registering: true,
        email: payload.email,
        password: payload.password,
        user_id: user.id,
        provider: 'email',
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  async verifyRegisterCode(code, email) {
    try {
      await axios.post(apiUrl('verify-register-code'), {
        register_code: code,
        email: email
      });

    } catch (error) {
      throw new Error(error);
    }
  }

  async linkSocialAccount(provider) {
    switch (provider) {
      case 'facebook':
        return await this.linkFacebookAccount();
      case 'google':
        return await this.linkGoogleAccount();
      default:
        return null;
    }
  }

  async linkFacebookAccount() {
    try {
      let socialAuthService;

      if (PlatformService.isNative()) {
        socialAuthService = new SocialAuthNativeService();
      } else {
        socialAuthService = new SocialAuthWebService();
      }

      const accessToken = await socialAuthService.loginWithFacebook();

      await axios.post(`${process.env.API_URL}/v1/facebook/link`, {token: accessToken})

      await userService.setProvider('facebook');

    } catch (error) {
      throw new Error(error);
    }
  }

  async linkGoogleAccount() {
    try {
      let socialAuthService;

      if (PlatformService.isNative()) {
        socialAuthService = new SocialAuthNativeService();
      } else {
        socialAuthService = new SocialAuthWebService();
      }

      const accessToken = await socialAuthService.loginWithGoogle();

      await axios.post(`${process.env.API_URL}/v1/google/link`, {token: accessToken})

      await userService.setProvider('google');

    } catch (error) {
      throw new Error(error);
    }
  }

  async logout() {
    try {
      await userService.removeUser();
      await userService.removeRegisterData();
      await userService.removeAuthTokenData();

    } catch (error) {
      throw new Error(error);
    }
  }
}

export const authService = new AuthService();
