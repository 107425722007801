import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {AppConfiguration} from "@/modules/app-configuration/models/AppConfiguration.js";

// Storage keys as constants
const STORAGE_KEYS = {
  APP_CONFIGURATION: 'app_configuration',
  APP_MESSAGES: 'app_messages',
  STORE: 'app_configuration_db'
};

export class AppConfigurationStore {
  constructor() {
    this.store = new Storage({
      driverOrder: [Drivers.IndexedDB, CordovaSQLiteDriver._driver],
    });

    this.store.create();
  }

  async set(key, value) {
    try {
      const config = await this.getAppConfiguration();
      if (config[key] === value) return;
      config[key] = value;
      await this.setAppConfiguration(config);
    } catch (error) {
      console.error(`Failed to set app configuration property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const config = await this.getAppConfiguration();
      return config[key];
    } catch (error) {
      console.error(`Failed to get app configuration property "${key}":`, error);
      return undefined;
    }
  }

  async setAppConfiguration(configuration) {
    try {
      const store = await this._getStore();
      store.app_configuration = configuration;
      await this.store.set(STORAGE_KEYS.STORE, store);
    } catch (error) {
      console.error('Failed to set app configuration:', error);
    }
  }

  async getAppConfiguration() {
    try {
      const store = await this._getStore();
      return store.app_configuration ? new AppConfiguration(store.app_configuration) : null;
    } catch (error) {
      console.error('Failed to retrieve app configuration:', error);
      return {};
    }
  }

  async addAppMessage(message) {
    try {
      const store = await this._getStore();
      if (!store.app_messages) {
        store.app_messages = [];
      }

      // Check if message with same ID already exists
      const isDuplicate = store.app_messages.some(existingMessage =>
        existingMessage.id === message.id
      );

      if (!isDuplicate) {
        store.app_messages.push(message);
        await this.store.set(STORAGE_KEYS.STORE, store);
      }
    } catch (error) {
      console.error('Failed to add app message:', error);
    }
  }

  async getAppMessages() {
    try {
      const store = await this._getStore();
      return store.app_messages || [];
    } catch (error) {
      console.error('Failed to get app messages:', error);
      return [];
    }
  }

  async dismissAppMessage(id) {
    try {
      const store = await this._getStore();

      store.app_messages = store.app_messages.map(message => {
        if (message.id === id) {
          message.dismissed = true;
        }
        return message;
      });

      await this.store.set(STORAGE_KEYS.STORE, store);
    } catch (error) {
      console.error('Failed to dismiss app message:', error);
    }
  }

  async clearAppConfiguration() {
    try {
      const store = await this._getStore();
      store.app_configuration = null;
      await this.store.set(STORAGE_KEYS.STORE, store);
    } catch (error) {
      console.error('Failed to clear app configuration:', error);
    }
  }

  async _getStore() {
    try {
      const store = await this.store.get(STORAGE_KEYS.STORE);
      return store || { app_configuration: null, app_messages: [] };
    } catch (error) {
      console.error('Failed to get store:', error);
      return { app_configuration: null, app_messages: [] };
    }
  }
}
