<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header">
        <h3>
          <template v-if="deliveryMode === 'delivery'">
            <Icon name="addresses" />
            {{ __t('app.initial-search-modal.title') }}
          </template>
          <template v-else>
            <Icon name="address-establishment" />
            {{ __t('app.initial-search-modal.title.takeaway') }}
          </template>
        </h3>
      </div>
      <div class="modal-main mb-l">
        <div class="modal-search-input">
          <search-address-input
            v-if="loaded"
            id="search-input"
            :auto-submit="false"
            :label="__t('app.initial-search-modal.label')"
            :placeholder="deliveryMode === 'delivery'
              ? __t('app.initial-search-modal.placeholder')
              : __t('app.initial-search-modal.placeholder.takeaway')"
            @address-selected="setCurrentAddress"
          />
        </div>
        <span v-if="error" class="error-message">{{ __t('error.banner.search') }}</span>
      </div>
    </div>
  </ion-content>
  <ion-footer style="box-shadow: none;">
    <app-button :disabled="!canSearch" :loading="locationUpdated" @click="submitSearch">
      {{ __t('app.modal.submit') }}
    </app-button>

    <DeliveryToggle class="mt-m" />
  </ion-footer>
</template>

<script setup>
  import {nextTick, onMounted, ref} from 'vue';
  import Icon from '@/ui/icon/Icon.vue';
  import SearchAddressInput from '@/modules/global/SearchAddressInput.vue';
  import {modalController, IonHeader, IonContent, IonFooter} from "@ionic/vue";
  import DeliveryToggle from "@/modules/order/parts/DeliveryToggle.vue";
  import AppButton from "@/ui/button/AppButton.vue";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api.js";
  import {$showToast, __t} from "../globals.js";
  import {cartService} from "@/flows/order/cart/services/cartService.js";
  import Address from "@/resources/Address.js";
  import store from "@/store/store.js";
  import {addressService} from "@/flows/app/address/services/AddressService.js";

  // Define reactive state
  const currentAddress = ref(null);
  const canSearch = ref(false);

  const error = ref(false);
  const loaded = ref(false);

  const deliveryMode = ref('delivery');
  const establishment = ref(null);
  const cartSummary = ref(null);
  const locationUpdated = ref(false);

  // Animate modal on mount
  onMounted(async () => {
    locationUpdated.value = false;

    await nextTick(() => {
      waitForGoogleAPI();
    });

    deliveryMode.value = await cartService.getDeliveryMode();
    establishment.value = await cartService.getEstablishment();
    cartSummary.value = await cartService.getCartSummary();
  });

  const setCurrentAddress = (address) => {
    currentAddress.value = address;

    if (address) {
      canSearch.value = true;
    }
  };

  const submitSearch = async () => {
    try {
      error.value = false;

      const address = new Address(currentAddress.value);

      locationUpdated.value = true;

      if(!address) {
        return;
      }

      await addressService.setAddress(address);

      await store.dispatch('updateAddressStatus');

      if(establishment.value) {
        await fetchEstablishment(address);
      }

      $showToast(__t('success.location.updated'), 'success');

      await modalController.dismiss();
    } catch (err) {
      console.error('Error submitting search:', err);
      error.value = true;
    } finally {
      locationUpdated.value = false;
    }
  };

  const fetchEstablishment = async (address) => {
    const slug = establishment.value.slug;

    await axios.get(apiUrl(
      `customer/establishment/${slug}?latitude=${address.latitude}&longitude=${address.longitude}`)
    ).then(async ({data}) => {
      // set minimum order value
      await cartService.setMinOrderAmount(data.data.min_order_value);

      await cartService.setDeliveryCost(data.data.delivery_price);

      // set delivery cost

      console.log('api delivery cost:', data.data.delivery_price);
      console.log('cart delivery cost:', data.data.delivery_price);
    })
  };

  const waitForGoogleAPI = () => {
    const maxAttempts = 50;
    let attempts = 0;

    const checkAPI = () => {
      if (window.google) {
        loaded.value = true;
        return;
      }

      attempts++;
      if (attempts >= maxAttempts) {
        error.value = true;
        loaded.value = true;
        console.error('Google Maps API failed to load');
        return;
      }

      setTimeout(checkAPI, 100);
    };

    checkAPI();
  };
</script>

<style lang="scss">
.ion-modal-search-initial {
  --height: 36rem;

  .modal-main {
    margin: auto;
    max-width: 38rem;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }

  ion-footer {
    max-width: 38rem;
    box-shadow: none;
    display: flex;
    margin: auto;
    padding: var(--padding-m) 0;
    padding-bottom: var(--padding-l);
    flex-direction: column;
    align-items: center;
  }
}

[data-theme='dark'] {
  .ion-modal-search-initial {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }


    button.r2e-button {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);
    }

    .corner-mask {
      background-color: var(--r2e-secondary) !important;
    }

    button.search {
      background-color: transparent;
    }

  }
}

</style>
