import { createRouter, createWebHistory } from '@ionic/vue-router';
import routes from '@/router/routes';
import { generateLocalizedRoutes } from './utils/localizedRoutes';
import { localeMiddleware } from './middleware/localeMiddleware';
import { appStateMiddleware } from './middleware/appStateMiddleware';
import PlatformService from '@/core/PlatformService.js';
import { useFilter } from '@/configuration/filter';
import {themeMiddleware} from "@/router/middleware/themeMiddleware.js";
import {authTokenExpiredMiddleware} from "@/router/middleware/authTokenExpiredMiddleware.js";
import {appMessageMiddleware} from "@/router/middleware/appMessageMiddleware.js";

// Initial locale setup
const initialLocale = localStorage.getItem('locale') || 'nl';

const routeList = PlatformService.isNative()
  ? routes
  : generateLocalizedRoutes(routes, initialLocale);

const router = createRouter({
  history: createWebHistory('/'),
  routes: routeList,
});

// Middleware execution
router.beforeEach(async (to, from, next) => {
  const middlewares = [
    localeMiddleware,
    themeMiddleware,
    authTokenExpiredMiddleware,
    appMessageMiddleware
  ];

  try {
    // Check maintenance first
    const maintenanceCheck = await appStateMiddleware(to);
    if (!maintenanceCheck && to.name !== 'maintenance') {
      return next({ name: 'maintenance' });
    }

    for (const middleware of middlewares) {
      await middleware(to, from, next);
    }

    if (to.path !== from.path) {
      const filter = useFilter();
      filter.resetFilter();
    }

    next();
  } catch (error) {
    console.error('Router middleware error:', error);
    next(false);
  }
});

export default router;
