<template>
  <svg
    class="toggle"
    :class="{ active }"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 21"
    fill="currentColor"
    @click="emitToggle"
  >
    <path d="M44 10.3889C44 16.1265 39.3487 20.7778 33.6111 20.7778C27.8735 20.7778 23.2222 16.1265 23.2222 10.3889C23.2222 4.65126 27.8735 0 33.6111 0C39.3487 0 44 4.65126 44 10.3889Z" />
    <path d="M9.77777 0H34.2222V20.7778H9.77777V0Z" />
    <path d="M20.7778 10.3889C20.7778 16.1265 16.1265 20.7778 10.3889 20.7778C4.65126 20.7778 0 16.1265 0 10.3889C0 4.65126 4.65126 0 10.3889 0C16.1265 0 20.7778 4.65126 20.7778 10.3889Z" />
    <circle :cx="active ? 33 : 10.6111" cy="10.3888" r="7.94444" />
  </svg>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';

  defineProps({
    active: { type: Boolean, default: false }
  });

  const emit = defineEmits(['toggle']);

  const emitToggle = () => {
    emit('toggle');
  };
</script>

<style lang="scss">
.toggle {
  width: 4.4rem;
  height: 2.1rem;
  cursor: pointer;
  fill: var(--r2e-secondary-200);
  transition: var(--effect);

  &.delivery-toggle {
    fill: var(--r2e-secondary);
  }

  circle {
    transition: var(--effect);
    fill: #fff;
  }

  &.active {
    fill: var(--r2e-primary);
    circle {
      fill: #fff;
    }
  }
}

[data-theme='dark'] {
  .toggle {
    fill: var(--r2e-secondary-500);

    &.active {
      fill: var(--r2e-primary);
    }
  }
}
</style>
