<template>
  <div class="r2e-select" :class="{ error: error }">
    <label v-if="label" :for="`select-${id}`">{{ label }}</label>
    <div class="select-field" :class="{ disabled: disabled }">
      <ion-select
        :id="`select-${id}`"
        v-model="value"
        :placeholder="placeholder"
        :disabled="disabled"
        interface="popover"
        @ion-change="updateSelected"
      >
        <ion-select-option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </ion-select-option>
      </ion-select>
    </div>
    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<script setup>
  import { ref, onBeforeMount, defineProps, defineEmits } from 'vue';
  import { IonSelect, IonSelectOption } from '@ionic/vue';
  import { __t } from "@/globals";

  const props = defineProps({
    id: { type: String, required: true },
    placeholder: { type: String, required: false, default: __t('app.select') },
    options: { type: Array, required: false, default: () => [] },
    selected: { type: String, required: false, default: null },
    label: { type: String, required: false, default: null },
    name: { type: String, required: false, default: null },
    error: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  });

  const emit = defineEmits(['setter', 'removeError']);

  const value = ref(null);

  onBeforeMount(() => {
    value.value = props.selected;
  });

  function updateSelected(event) {
    value.value = event.detail.value;
    emit('setter', value.value);
    emit('removeError');
  }
</script>

<style lang="scss">
.r2e-select {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  .select-field.disabled {
    opacity: 0.3;
  }

  .select-field ion-select {
    --background: var(--r2e-secondary-100);
    --border-color: transparent;
    --border-radius: 4px;
    --border-style: solid;
    --border-width: 1px;
    --color: var(--r2e-secondary);
    --placeholder-color: var(--r2e-secondary-200);
    --highlight-color-focused: var(--r2e-secondary-200);
    --highlight-color-invalid: transparent;
    --highlight-color-valid: transparent;
    --placeholder-opacity: 1;

    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    height: 6.2rem;
    padding-left: 0;
    text-indent: var(--margin-m);
    transition: var(--effect);

    &::part(icon) {
      opacity: 1;
      width: 2rem;
      height: 2rem;
    }

    &::part(placeholder) {
      color: var(--r2e-secondary-200);
    }

    &:hover {
      --border-color: var(--r2e-secondary-200);
      --placeholder-opacity: 0.8;
    }

    &.select-expanded {
      --border-color: var(--r2e-secondary);
      box-shadow: 0 0 0 1px var(--r2e-secondary);
    }
  }

  span.error {
    color: var(--r2e-error);
    font-size: 1.2rem;
  }

  &.error .select-field ion-select {
    --background: var(--r2e-primary-100);
    --color: var(--r2e-error);
    --placeholder-color: var(--r2e-error);
  }
}

// Styling for the popover
ion-select-popover {
  --background: var(--r2e-secondary-100);
  --width: 100%;
  max-width: 300px;

  ion-item {
    --background: transparent;
    --color: var(--r2e-secondary);
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    height: 4rem;

    &.item-radio-checked {
      --background: var(--r2e-primary);
      --color: #fff;
    }
  }
}
.item-radio-checked.sc-ion-select-popover-md {
  --background: var(--r2e-secondary-200) !important;
}


[data-theme='dark'] {
  .r2e-select {
    label {
      color: var(--r2e-secondary-200);
    }

    .select-field ion-select {
      --background: var(--r2e-secondary-500); // Changed from 500 to 600
      --color: var(--r2e-secondary-100); // Changed from white to secondary-200
      --placeholder-color: var(--r2e-secondary-200); // Changed from 200 to 300 for better contrast
      --placeholder-opacity: 0.5;
      color: var(--r2e-secondary-100); // Changed from white to secondary-200

      &:hover {
        --border-color: var(--r2e-secondary-300); // Added hover state
      }

      &.select-expanded {
        --border-color: var(--r2e-secondary-200);
        box-shadow: 0 0 0 1px var(--r2e-secondary-200);
      }
    }

    &.error .select-field ion-select {
      --background: var(--r2e-primary-700);
      --color: var(--r2e-error);
    }
  }

  ion-select-popover {
    --background: var(--r2e-secondary-600); // Changed from 500 to 600
    background-color: var(--r2e-secondary-600); // Changed from 500 to 600

    ion-item {
      --color: var(--r2e-secondary-200); // Changed from white to secondary-200

      &.item-radio-checked {
        --background: var(--r2e-primary);
        --color: var(--r2e-white);
      }
    }
  }
}
</style>
