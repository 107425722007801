import {appConfigurationService} from "@/modules/app-configuration/services/AppConfigurationService.js";
import {alertController} from "@ionic/vue";
import {__t} from "@/globals.js";


export async function appMessageMiddleware(to) {
    let messages = await appConfigurationService.getAppMessages();

    // get only messages that are not dismiss
    messages = messages.filter(message => message.dismissed === false);

    if(messages.length === 0) {
        return;
    }

    await openMessage(messages[0]);
}

async function openMessage(message) {
    const buttonText = __t('app.alert.dismiss');

    const alert = await alertController.create({
        header: message.title,
        message: message.body,
        buttons: [{
            text: buttonText
        }],
        cssClass: 'ion-alert-message'
    });

    alert.onDidDismiss().then(async () => {
        await appConfigurationService.dismissAppMessage(message.id);
    });

    await alert.present();
}

