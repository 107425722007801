export default [
  {
    name: 'home',
    path_nl: '/',
    path_en: '/',
    path_fr: '/',
    path_tr: '/',
    redirect: 'catalogue'
  },

  {
    name: 'login',
    path_nl: '/aanmelden',
    path_en: '/login',
    path_fr: '/connexion',
    path_tr: '/giris',
    component: () => import('@/flows/user/authentication/views/Login.vue'),
  },

  {
    name: 'native.login',
    path_nl: '/native/aanmelden',
    path_en: '/native/login',
    path_fr: '/native/connexion',
    path_tr: '/yerli/giris',
    component: () => import('@/flows/user/authentication/views/NativeLogin.vue'),
  },

  {
    name: 'native.register',
    path_nl: '/native/registreren',
    path_en: '/native/register',
    path_fr: '/native/inscription',
    path_tr: '/yerli/kayit',
    component: () => import('@/flows/user/authentication/views/NativeRegister.vue'),
  },

  {
    name: 'register',
    path_nl: '/registreren',
    path_en: '/register',
    path_fr: '/inscription',
    path_tr: '/kayit',
    component: () => import('@/flows/user/authentication/views/Register.vue'),
  },

  {
    name: 'register.code',
    path_nl: '/registreren/verifieren',
    path_en: '/register/verify',
    path_fr: '/inscription/verifier',
    path_tr: '/kayit/dogrulama',
    component: () => import('@/flows/user/authentication/views/RegisterCode.vue'),
  },

  {
    name: 'register.code.without-login',
    path_nl: '/registreren/alt/verifieren',
    path_en: '/register/alt/verify',
    path_fr: '/inscription/alt/verifier',
    path_tr: '/kayit/alt/dogrulama',
    component: () => import('@/flows/user/authentication/views/RegisterCodeWithoutLogin.vue'),
  },

  {
    name: 'register.code.social',
    path_nl: '/registreren/social/verifieren',
    path_en: '/register/social/verify',
    path_fr: '/inscription/social/verifier',
    path_tr: '/kayit/sosyal/dogrulama',
    component: () => import('@/flows/user/authentication/views/RegisterSocialCode.vue'),
  },

  {
    name: 'forgot-password',
    path_nl: '/wachtwoord-vergeten',
    path_en: '/forgot-password',
    path_fr: '/mot-de-passe-oublie',
    path_tr: '/sifre-unutma',
    component: () => import('@/flows/user/authentication/views/ForgotPassword.vue'),
  },

  {
    name: 'request-password-reset',
    path_nl: '/wachtwoord-resetten',
    path_en: '/password-reset',
    path_fr: '/reinitialiser-mot-de-passe',
    path_tr: '/sifre-sifirlama',
    component: () => import('@/flows/user/authentication/views/RequestResetPassword.vue'),
  },

  {
    name: 'password-reset',
    path_nl: '/nieuw-wachtwoord',
    path_en: '/new-password',
    path_fr: '/nouveau-mot-de-passe',
    path_tr: '/yeni-sifre',
    component: () => import('@/flows/user/authentication/views/ResetPassword.vue'),
  },

  {
    name: 'catalogue',
    path_nl: '/bestellen',
    path_en: '/order',
    path_fr: '/commander',
    path_tr: '/siparis',
    component: () => import('@/views/Catalogue.vue'),
  },

  {
    name: 'establishment',
    path_nl: '/bestellen/:slug',
    path_en: '/order/:slug',
    path_fr: '/commander/:slug',
    path_tr: '/siparis/:slug',
    component: () => import('@/views/Establishment.vue'),
    meta: {
      deliveryCheck: true
    }
  },

  {
    name: 'cart',
    path_nl: '/winkelwagen',
    path_en: '/cart',
    path_fr: '/panier',
    path_tr: '/sepet',
    component: () => import('@/views/Cart.vue'),
    meta: {
      deliveryCheck: true
    }
  },

  {
    name: 'checkout',
    path_nl: '/checkout',
    path_en: '/checkout',
    path_fr: '/paiement',
    path_tr: '/odeme',
    component: () => import('@/views/Checkout.vue'),
    meta: {
      deliveryCheck: true
    }
  },

  {
    name: 'follow-order',
    path_nl: '/bestelling-volgen/:nr',
    path_en: '/follow-order/:nr',
    path_fr: '/suivre-commande/:nr',
    path_tr: '/siparis-takip/:nr',
    component: () => import('@/flows/order/follow/components/views/FollowOrderView.vue'),
  },

  // {
  //   name: 'order-follow-by-nr',
  //   path_nl: '/bestelling-volgen/:nr',
  //   path_en: '/follow-order/:nr',
  //   path_fr: '/suivre-commande/:nr',
  //   path_tr: '/siparis-takip/:nr',
  //   component: () => import('@/flows/order/follow/components/views/FollowOrderView.vue'),
  // },

  {
    name: 'order-history',
    path_nl: '/bestelgeschiedenis',
    path_en: '/order-history',
    path_fr: '/historique-commandes',
    path_tr: '/siparis-gecmisi',
    component: () => import('@/views/OrderHistory.vue'),
  },

  {
    name: 'order-history-detail',
    path_nl: '/bestelgeschiedenis/:nr',
    path_en: '/order-history/:nr',
    path_fr: '/historique-commandes/:nr',
    path_tr: '/siparis-gecmisi/:nr',
    component: () => import('@/views/OrderHistoryDetail.vue'),
  },

  {
    name: 'account',
    path_nl: '/account',
    path_en: '/profile',
    path_fr: '/profil',
    path_tr: '/profil',
    component: () => import('@/flows/user/user/views/Account.vue'),
  },

  {
    name: 'account.update',
    path_nl: '/account/bewerken',
    path_en: '/profile/edit',
    path_fr: '/profil/modifier',
    path_tr: '/profil/duzenle',
    component: () => import('@/flows/user/user/views/AccountUpdate.vue'),
  },

  {
    name: 'contact',
    path_nl: '/contact',
    path_en: '/contact',
    path_fr: '/contact',
    path_tr: '/iletisim',
    component: () => import('@/views/Contact.vue'),
  },

  {
    name: 'settings',
    path_nl: '/instellingen',
    path_en: '/settings',
    path_fr: '/parametres',
    path_tr: '/ayarlar',
    component: () => import('@/views/Settings.vue'),
  },

  {
    name: 'no-connection',
    path_nl: '/geen-verbinding',
    path_en: '/no-connection',
    path_fr: '/pas-de-connexion',
    path_tr: '/baglanti-yok',
    component: () => import('@/views/errors/ErrorNoConnection.vue'),
  },

  {
    name: 'not-found',
    path_nl: '/404',
    path_en: '/404',
    path_fr: '/404',
    path_tr: '/404',
    component: () => import('@/views/errors/Error404.vue'),
  },

  {
    name: 'maintenance',
    path_nl: '/onderhoud',
    path_en: '/maintenance',
    path_fr: '/maintenance',
    path_tr: '/bakim',
    component: () => import('@/views/errors/ErrorMaintenance.vue'),
  },

  {
    path_nl: '/:pathMatch(.*)*',
    path_en: '/:pathMatch(.*)*',
    path_fr: '/:pathMatch(.*)*',
    path_tr: '/:pathMatch(.*)*',
    redirect: 'not-found',
  },
]
